import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import ReactGA from 'react-ga';

import UnauthenticatedRoute from 'components/utils/UnauthenticatedRoute';
import AuthenticatedRoute from 'components/utils/AuthenticatedRoute';
import asyncComponent from 'components/utils/AsyncComponent';
import { Main as MainLayout, Minimal as MinimalLayout } from './layouts';
import WithLayout from 'WithLayout';

const AsyncNotFound = asyncComponent(() => import('./views/NotFound'));
const AsyncLandingPage = asyncComponent(() => import('./views/DesktopApp'));
const AsyncSignIn = asyncComponent(() => import('./views/SigninSimple'));
const AsyncPrivacyPolicy = asyncComponent(() =>
  import('./views/PrivacyPolicy'),
);
const AsyncTermsOfUse = asyncComponent(() => import('./views/TermsOfUse'));
const AsyncContactPage = asyncComponent(() => import('./views/ContactPage'));
const AsyncHelpCenter = asyncComponent(() => import('./views/HelpCenter'));
const AsyncFreeTrialForm = asyncComponent(() =>
  import('./views/FreeTrialForm'),
);
const AsyncLearnMore = asyncComponent(() => import('./views/LearnMore'));
const AsyncPricing = asyncComponent(() => import('./views/Pricing'));

const AsyncAccount = asyncComponent(() =>
  import('./views/Account'),
);

export default function Routes({ childProps }) {
  const location = useLocation();
  ReactGA.pageview(location.pathname);


  return (
    <Switch>
      <UnauthenticatedRoute
        exact
        path="/pricing"
        props={childProps}
        component={AsyncPricing}
        layout={MainLayout}
      />
      <UnauthenticatedRoute
        exact
        path="/contact"
        props={childProps}
        component={AsyncContactPage}
        layout={MainLayout}
      />
      <UnauthenticatedRoute
        exact
        path="/free-trial"
        props={childProps}
        component={AsyncFreeTrialForm}
        layout={MainLayout}
      />

      <UnauthenticatedRoute
        exact
        path="/sign-in"
        props={childProps}
        component={AsyncSignIn}
        layout={MainLayout}
      />
      <UnauthenticatedRoute
        exact
        path="/privacy-policy"
        props={childProps}
        component={AsyncPrivacyPolicy}
        layout={MainLayout}
      />
      {/* <UnauthenticatedRoute
        exact
        path="/frequently-asked-questions"
        props={childProps}
        component={AsyncHelpCenter}
        layout={MainLayout}
      /> */}

      <UnauthenticatedRoute
        exact
        path="/terms-of-use"
        props={childProps}
        component={AsyncTermsOfUse}
        layout={MainLayout}
      />
      <UnauthenticatedRoute
        exact
        path="/learn-more"
        props={childProps}
        component={AsyncLearnMore}
        layout={MainLayout}
      />
      <AuthenticatedRoute
        path="/logged-in/page-2"
        component={AsyncAccount}
        props={childProps}

      />
      <AuthenticatedRoute
        exact
        path="/logged-in/page-1"
        component={AsyncAccount}
        props={childProps}

      />
      <UnauthenticatedRoute
        exact
        path="/"
        props={childProps}
        component={AsyncLandingPage}
        layout={MainLayout}
      />


      {/* Finally, catch all unmatched routes */}
      <WithLayout layout={MainLayout} component={AsyncNotFound} />
    </Switch>
  );
}
