import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useAppContext } from 'libs/contextLib';
import { Main as MainLayout, Minimal as MinimalLayout } from 'layouts';
import WithLayout from 'WithLayout';

function querystring(name, url = window.location.href) {
  name = name.replace(/[[]]/g, '\\$&');

  const regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)', 'i');
  const results = regex.exec(url);

  if (!results) {
    return null;
  }
  if (!results[2]) {
    return '';
  }

  return decodeURIComponent(results[2].replace(/\+/g, ' '));
}

export default function UnauthenticatedRoute({ component, ...rest }) {
  const { isAuthenticated } = useAppContext();
  const redirect = querystring('redirect');

  console.log('inside Unauthenticated route : ', isAuthenticated)
  return (
    <Route {...rest}>
      {!isAuthenticated ? (
        <WithLayout
          layout={MainLayout}
          component={component}
          isAuthenticated={isAuthenticated}
        />
      ) : (
        <Redirect to={redirect === '' || redirect === null ? '/logged-in/page-1' : redirect} />
      )}
    </Route>
  );
}
