import React from 'react';
import { Route, Redirect, useLocation } from 'react-router-dom';
import { useAppContext } from 'libs/contextLib';
import WithLayout from 'WithLayout';
import { Main as MainLayout, Minimal as MinimalLayout } from 'layouts';

export default function AuthenticatedRoute({ component, ...rest }) {
  const { pathname, search } = useLocation();
  const { isAuthenticated } = useAppContext();
  console.log("Inside authenticated route, isAuth: ", isAuthenticated)

  return (
    <Route {...rest}>
      {isAuthenticated ? (
        <WithLayout
          layout={MainLayout}
          component={component}
          isAuthenticated={isAuthenticated}
        />
      ) : (
        <Redirect to={`/sign-in`} />
      )}
    </Route>
  );
}
