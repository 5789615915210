/**
 * Caution: Consider this file when using react-scripts
 *
 * You may delete this file and its occurrences from the project filesystem if you are using GatsbyJS or NextJS version
 */
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import ReactGA from 'react-ga'

import * as serviceWorker from './serviceWorker';
import App from './App';
// import config from './config.js';


ReactGA.initialize("UA-189848218-1");
ReactGA.pageview("/");



ReactDOM.render(
  // <React.StrictMode history={history}>
  <Router basename="/"  >
    <App />
  </Router>,
  // </React.StrictMode>,
  document.getElementById('root'),
);

serviceWorker.unregister();
