/**
 * Caution: Consider this file when using react-scripts
 *
 * You may delete this file and its occurrences from the project filesystem if you are using GatsbyJS or NextJS version
 */
import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import Routes from './routes';
import { AppContext } from './libs/contextLib';
import ErrorBoundary from './components/utils/ErrorBoundary';
import { onError } from './libs/errorLib';
import ReactGA from 'react-ga';
import Cookies from 'js-cookie';

import 'react-lazy-load-image-component/src/effects/opacity.css';
import 'assets/css/index.css';
import 'aos/dist/aos.css';

function App() {
  const history = useHistory();
  const [isAuthenticating, setIsAuthenticating] = useState(true);
  const [isAuthenticated, userHasAuthenticated] = useState(false);

  useEffect(() => {
    onLoad();
  }, []);

  async function onLoad() {
    try {
      if (Cookies.get('access_token')) {
        userHasAuthenticated(true);
      } else {
        userHasAuthenticated(false);
      }
    } catch (e) {
      if (e !== 'No current user') {
        onError(e);
      }
    }
    setIsAuthenticating(false);
  }

  // prevents circular loop on initial load
  if (isAuthenticating) {
    return <div>LOADING</div>;
  }

  return (
    <div>
      <ErrorBoundary>
        <AppContext.Provider
          value={{
            isAuthenticated,
            userHasAuthenticated,
          }}
        >
          <Routes />
        </AppContext.Provider>
      </ErrorBoundary>
    </div>
  );
}

export default App;
